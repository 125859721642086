export default function Loading() {
  return (
    <div className="orbiter-loading">
      <div className="orbiter-loading-bar"></div>
      <div className="orbiter-loading-bar"></div>
      <div className="orbiter-loading-bar"></div>
      <div className="orbiter-loading-bar"></div>
      <div className="orbiter-loading-bar"></div>
      <div className="orbiter-loading-bar"></div>
      <div className="orbiter-loading-bar"></div>
      <div className="orbiter-loading-bar"></div>
    </div>
  );
}
